import React from 'react';

function LinkIcon() {
    return (
        <span data-uk-icon="teenyicons-solid--arrow-right" className="uk-icon">
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" width="20" height="20">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.854 3.146L14.207 7.5l-4.353 4.354-.708-.708L12.293 8H1V7h11.293L9.146 3.854l.708-.708z"
                    fill="#000"
                />
            </svg>
        </span>
    );
}

export default LinkIcon;
